import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ic4 from "../../assets/images/ic4.png";
import ic5 from "../../assets/images/ic5.png";
import ic6 from "../../assets/images/ic6.png";
import ic7 from "../../assets/images/ic7.png";
import ic8 from "../../assets/images/ic8.png";
import ic9 from "../../assets/images/ic9.png";
import LogoFooter from "../LogoFooter/LogoFooter";
import "./Footer.scss";
import { useTranslation } from "react-i18next";
import { request } from "../../helpers/api";
import { LanguageSupported } from "../../helpers/language";
import { SocialLink } from "../../helpers/social-link";

export default function Footer({}) {
  const { t, i18n } = useTranslation();
  const location = useLocation().pathname;

  const defaultValueForMenu = [{ id: 0, path: "", name: "" }];

  const [arrMenu3, setArrMenu3] = useState(defaultValueForMenu);
  const [arrMenu2, setArrMenu2] = useState(defaultValueForMenu);
  const [arrMenu1, setArrMenu1] = useState(defaultValueForMenu);

  useEffect(() => {
    setArrMenu3(defaultValueForMenu);
    setArrMenu2(defaultValueForMenu);

    setArrMenu1([
      {
        id: 1,
        path: `/${i18n.language}/about-us`,
        name: "nav_top.nav_item_1.sub_2",
      },
      // { id: 2, path: "/partners", name: "nav_top.nav_item_1.sub_3" },
      {
        id: 3,
        path: `/${i18n.language}/core-teams`,
        name: "nav_top.nav_item_1.sub_4",
      },
      {
        id: 4,
        path: `/${i18n.language}/solutions`,
        name: "nav_top.nav_item_1.sub_5",
      },
    ]);

    if (i18n.language === LanguageSupported.VI.name) {
      request("GET", "course-types", i18n.language, "&sort[0]=id%3Aasc").then(
        (response) => {
          if (i18n.language === LanguageSupported.VI.name) {
            const arrData = response.data;
            let arrMenu = [
              { id: 0, path: `/${i18n.language}/schedules/all`, name: "" },
            ];
            arrData.forEach((element) => {
              arrMenu.push({
                id: element.id,
                path: `/${i18n.language}/schedules/${element.attributes.slug}`,
                name: element.attributes.blockMenuNameTitle,
              });
            });

            setArrMenu3(arrMenu);
          }
        }
      );
    }

    request("GET", "solution-systems", i18n.language, "&sort[0]=id%3Aasc").then(
      (response) => {
        const arrData = response.data;
        let arrMenu = [];
        arrData.forEach((element) => {
          arrMenu.push({
            id: element.id,
            path: `/${i18n.language}/solution-systems/${element.attributes.slug}`,
            name: element.attributes.name,
            description: element.attributes.description,
          });
        });

        setArrMenu2(arrMenu);
      }
    );
  }, [i18n.language]);

  return (
    <footer className="footer">
      <div className="content-w size-lg">
        <div className="top">
          <div className="bl blLeft">
            <LogoFooter />
            <div className="slogan mt-20">
              Help Organizations Transform Strategies<br></br> to Result Through
              People
            </div>
          </div>
          <div className="bl blRight">
            <div className="ft-menu">
              <div className="column">
                <div className="label">{t("nav_top.nav_item_1.sub_1")}</div>
                <ul>
                  {arrMenu1.map((item) => (
                    <li key={item.id}>
                      {" "}
                      <Link
                        to={`.${item.path}`}
                        className={`lv1  ${
                          location == item.path ? "active" : ""
                        }`}
                      >
                        {t(item.name)}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="column">
                <div className="label">{t("nav_top.nav_item_2.sub_1")}</div>
                <ul>
                  {arrMenu2.map((item) =>
                    item.id > 0 ? (
                      <li key={item.id}>
                        {" "}
                        <Link
                          to={`.${item.path}`}
                          className={`lv1  ${
                            location == item.path ? "active" : ""
                          }`}
                        >
                          <p
                            dangerouslySetInnerHTML={{ __html: item.name }}
                          ></p>
                        </Link>
                      </li>
                    ) : (
                      ""
                    )
                  )}
                </ul>
              </div>
              {arrMenu3.length > 1 && arrMenu3[0].path && (
                <div className="column">
                  <div className="label">{t("nav_top.nav_item_3.sub_1")}</div>
                  <ul>
                    {arrMenu3.map((item) =>
                      item.id > 0 ? (
                        <li key={item.id}>
                          {" "}
                          <Link
                            to={`.${item.path}`}
                            className={`lv1  ${
                              location == item.path ? "active" : ""
                            }`}
                          >
                            <p
                              dangerouslySetInnerHTML={{ __html: item.name }}
                            ></p>
                          </Link>
                        </li>
                      ) : (
                        ""
                      )
                    )}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="bottom">
          <div className="copy-right">
            Copyright © 2023 SeedGroup. All rights reserved
          </div>
          <div className="social">
            <ul>
              <li>
                <a target="_blank" className="item" href={SocialLink.FaceBook.name}>
                  <div className="ic">
                    <div className="img">
                      <img src={ic4} alt="" />
                    </div>
                  </div>
                </a>
              </li>
              {/* <li>
                <a target="_blank" className="item" href={SocialLink.}>
                  <div className="ic">
                    <div className="img">
                      <img src={ic5} alt="" />
                    </div>
                  </div>
                </a>
              </li> */}
              <li>
                <a target="_blank" className="item" href={SocialLink.Instagram.name}>
                  <div className="ic">
                    <div className="img">
                      <img src={ic6} alt="" />
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a target="_blank" className="item" href={SocialLink.LinkedIn.name}>
                  <div className="ic">
                    <div className="img">
                      <img src={ic7} alt="" />
                    </div>
                  </div>
                </a>
              </li>
              {/* <li>
                <a className="item" href="#!">
                  <div className="ic">
                    <div className="img">
                      <img src={ic8} alt="" />
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a className="item" href="#!">
                  <div className="ic">
                    <div className="img">
                      <img src={ic9} alt="" />
                    </div>
                  </div>
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
