import React from "react";
import { Link } from "react-router-dom";
import "./Member.scss";

export default function Member({ name, role, avata, isSquare }) {
    return (
        <Link to={'./core-teams'} className={`member  ani scale ${isSquare ? "square" : ""}`} data-scroll>
            <div className="identify">
                <div className="avata">
                    <span className="aspectRatio aspectRatio--100" style={{ backgroundImage: `url(${avata})` }}></span>
                </div>
                <div className="meta ">
                    <div className="name mb-10">{name}</div>
                    <div className="role">
                        <p dangerouslySetInnerHTML={{ __html: role }}></p>​
                    </div>
                </div>
            </div>
        </Link>
    );
}
