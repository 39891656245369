import React from "react";
import closeMenu from "../../assets/images/icon-menu-close.png";
import Button from "../Button/Button";
import Logo2 from "../Logo2/Logo2";
import Menu from "../Menu/Menu";
import Search from "../Search/Search";
import "./NavBar.scss";
import { useTranslation } from 'react-i18next';

export default function NavBar({ onClick, closeIcon }) {
  const { t } = useTranslation();

  return (
    <div className="nav-bar">
      <div className="content-w size-lg">
        <div className="wrap ">
          <div className="block block-left">
            <div onClick={closeIcon}>
              <Logo2 />
            </div>
            <div className="close-menu" onClick={closeIcon}>
              <div className="img">
                <img src={closeMenu} />
              </div>
            </div>
          </div>
          <div className="block block-center">
            <Menu position="left" closeMenu={closeIcon} />
          </div>
          <div className="block block-right">
            {/* <Search /> */}
          </div>
        </div>
      </div>
      <div className="login">
        <div className="wrap">
          {/* <div onClick={onClick}> */}
          <div>
            {/* <Button className="" text="Login"></Button> */}
            <Button link="./constructor" text={t('login')}></Button>

          </div>
        </div>
      </div>
    </div>
  );
}
