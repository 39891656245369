import React from "react";
import BlockTitle from "../BlockTitle/BlockTitle";
import "./Banner.scss";

export default function Banner({ label, main, primary = "", banner, isNeedHighlight = false }) {
    return (
        <div className="banner aspectRatio aspectRatio--4-1" style={{ backgroundImage: `url(${banner})` }}>
            <div className="inner">
                <div className="content-w size-md">
                    <div className="wrap">
                        <BlockTitle
                            isNeedHighlight={isNeedHighlight}
                            label={label}
                            main={main}
                            primary={primary}
                            isBg
                            size_lg
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
