import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './../locales/en/translation.json';
import translationVN from './../locales/vi/translation.json';

const language = ['en', 'vi'];
const resources = {
    en: {
        translation: translationEN
    },
    vi: {
        translation: translationVN
    }
};

// @ts-ignore
i18next
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  //.use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: 'vi',
    resources,
    fallbackLng: 'vi',
    debug: true,
    whitelist: language
  });


export default i18next;