import React from "react";
import "./PageComponent.scss";
import banner from "../../assets/images/banner.jpg";
import Banner from "../../components/Banner/Banner";
import Button from "../../components/Button/Button";
import BannerSlider from "../../components/BannerSlider/BannerSlider";
import BlockTitle from "../../components/BlockTitle/BlockTitle";
import Card from "../../components/Card/Card";
import thumb1 from "../../assets/images/card1.png";

const PageComponent = () => {
    return (
        <div className="page PageComponent">
            <Banner label="" main="page" primary="componet" banner={banner} />
            <div className="content mt-60 mb-60">
                <div className="content-w size-md">
                    <div className="wrap">
                        <div className="mb-20">
                            <Button text="Button" />
                        </div>
                        <div className="mb-20">
                            <BannerSlider />
                        </div>
                        <div className="mb-20">
                            <BlockTitle main="hello" primary="helloe" label="sub" />
                        </div>
                        <div className="mb-20">
                            <Card title="title card" time="20-20-2002" thumb={thumb1} excerpt="askldad tawjkhaskhcc  ạdhakldhskjhd" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageComponent;
