import React from "react";
import "./modals.scss";
import Modal from "react-modal";
import Button from "../components/Button/Button";
import RegiterAccountForm from "../components/RegiterAccountForm/RegiterAccountForm";
import { useTranslation } from "react-i18next";

export default function RegiterAccount({ open, close }) {
  const { t } = useTranslation();
  return (
    <div>
      <Modal isOpen={open} contentLabel="Example Modal" className="block-modal">
        <div className="modal-close" onClick={close}>
          <div className="img"></div>
        </div>
        <div className="modal-content mb-20">
          <div className="modal-title">{t('contact.register_account_title')}</div>
         <RegiterAccountForm /> 
        </div>
        <div className="block-action ">
          <div className="item">
            <Button text={t('register_now')} color_main />
          </div>
          <div onClick={close} className="item">
            <Button grey text={t('close')} />
          </div>
        </div>
      </Modal>
    </div>
  );
}
