import React from "react";
import "./Feature.scss";

export default function Feature({
  icon,
  title,
  description,
  isDangerousHTML = false,
}) {
  return (
    <div className="feature">
      <div className="feature-icon">
        <div className="img">
          <img src={icon} />
        </div>
      </div>
      <div className="feature-meta">
        {isDangerousHTML ? (
          <div className="feature-meta">
            <div
              className="title"
              dangerouslySetInnerHTML={{ __html: title }}
            ></div>
            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: description }}
            ></div>
          </div>
        ) : (
          <div className="feature-meta">
            <div className="title">{title}</div>
            <div className="description">{description}</div>
          </div>
        )}
      </div>
    </div>
  );
}
