import React, { useContext, useEffect, useState } from "react";
import user from "../../assets/images/user.png";
import phone from "../../assets/images/phone.png";
import mail from "../../assets/images/mail.png";
import creditCard from "../../assets/images/credit-card.svg";
import "./RegiterCourseForm.scss";
import { useTranslation } from "react-i18next";
import {
  multipleRequest,
  postToURL,
  request,
  spreadArrayResponse,
} from "../../helpers/api";
import FormInput from "../FormInput/FormInput";
import Info from "../../modals/Info";
import { LoadingContext } from "../../helpers/loadingContext";

export default function RegiterCourseForm({ data, onClose }) {
  const context = useContext(LoadingContext);
  const { t, i18n } = useTranslation();

  const [paymentTypes, setPaymentTypes] = useState([
    {
      id: 0,
      attributes: {
        name: "",
        payment_gateways: { data: [] },
      },
    },
  ]);
  const [chosenPaymentType, setChosenPaymentType] = useState('0');
  const [defaultPaymentStatus, setDefaultPaymentStatus] = useState({
    id: 0,
    attributes: { name: "" },
  });
  const [paymentGateWays, setPaymentGateWays] = useState([
    {
      id: 0,
      attributes: {
        name: "",
        isNeedRedirect: false,
        webhookURL:"",
      },
    },
  ]);
  const [chosenPaymentGateWay, setChosenPaymentGateWay] = useState('');

  useEffect(() => {
    context.showLoading();

    multipleRequest([
      request("GET", "payment-types", i18n.language),
      request(
        "GET",
        "payment-statuses",
        i18n.language,
        "&filters[isDefault][$eq]=true"
      ),
    ])
      .then(
        spreadArrayResponse((obj1, obj2) => {
          const paymentTypes = obj1.data;
          if (paymentTypes && paymentTypes.length > 0) {
            setPaymentTypes(paymentTypes);
            setDefaultValue(paymentTypes[0]);
          }

          if (obj2 && obj2.data) {
            setDefaultPaymentStatus(obj2.data[0]);
          }

          context.hideLoading();
        })
      )
      .catch((err) => {
        context.showError();
      });

    request("GET", "payment-types", i18n.language)
      .then((response) => {
        const paymentTypes = response.data;

        if (paymentTypes && paymentTypes.length > 0) {
          setPaymentTypes(paymentTypes);
          setDefaultValue(paymentTypes[0]);
        }

        context.hideLoading();
      })
      .catch((err) => {
        context.showError();
      });
  }, [i18n.language]);

  const setDefaultValue = (paymentType) => {
    if (paymentType) {
      setChosenPaymentType(paymentType.id);
      const paymentGateWays = paymentType.attributes.payment_gateways.data;
      if (paymentGateWays && paymentGateWays.length > 0) {
        setPaymentGateWays(paymentGateWays);
        setChosenPaymentGateWay(paymentGateWays[0].id);
      } else {
        setPaymentGateWays([]);
        setChosenPaymentGateWay('');
      }
    }
  };

  useEffect(() => {
    if (chosenPaymentType) {
      const paymentType = paymentTypes.find((x) => x.id == parseInt(chosenPaymentType));
      setDefaultValue(paymentType);
    }
  }, [chosenPaymentType]);

  const onPaymentTypeChange = (event) => {
    if (event.target.value) {
      setChosenPaymentType(event.target.value);
    }
  };

  const onPaymentGateWayChange = (event) => {
    if (event.target.value) {
      setChosenPaymentGateWay(event.target.value);
    }
  };

  const [stateForm, setStateForm] = useState({
    courseName: data.course_template.data.attributes.name || null,
    courseTypeName:
      data.course_template.data.attributes.course_type.data.attributes.name ||
      null,
    fullName: "",
    phoneNumber: "",
    email: "",
    content: "",
    course: data.courseID ?? 0,
  });

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setStateForm({ ...stateForm, [name]: value });
  };
  const onHandleSubmit = (event) => {
    try {
      const timeStamp = Date.now();

      // create payment first
      const paymentData = {
        code: `${data.code} - ${timeStamp}`,
        price: data.course_template.data.attributes.price,
        isDeleted: false,
        payment_status: defaultPaymentStatus.id,
        payment_gateway: parseInt(chosenPaymentGateWay),
        paymentStatusMessage: "",
        timePaid: timeStamp,
      };

      request("POST", "payments", null, "", paymentData).then(newPayment => {
        if (newPayment != null && newPayment.data != null) {
          const dataSubmit = {
            name: stateForm.fullName,
            courseName: stateForm.courseName,
            courseTypeName: stateForm.courseTypeName,
            email: stateForm.email,
            phone: stateForm.phoneNumber,
            content: stateForm.content,
            isDeleted: false,
            course: stateForm.course,
            payment: newPayment.data.id,
          };
    
          request("POST", "course-requests", null, "", dataSubmit).then(response => {
            if (response != null && response.data != null) {
              const gateway = paymentGateWays.find(e => e.id == parseInt(chosenPaymentGateWay));
              if(gateway && gateway.attributes.isNeedRedirect) {
                // postToURL(gateway.attributes.webhookURL, newPayment).then(res => {
                //   //TODO : update later
                //   console.log(res);
                // }).catch(err => {
                //   context.showError();
                // })
                window.open(gateway.attributes.webhookURL);
              } else {
                setIsOpen(true);
              }
            }
          }).catch(err => {
            context.showError();
          }).catch(err => {
            context.showError();
          })
        }
      })

    } catch (error) {
      context.showError();
    } finally {
      event.preventDefault();
    }
  };
  const inputs = [
    {
      id: 1,
      name: "fullName",
      type: "text",
      placeholder: t("course_form.name.placeholder"),
      errorMessage: t("course_form.name.errorMessage"),
      iconImg: user,
      required: true,
    },
    {
      id: 2,
      name: "phoneNumber",
      type: "text",
      placeholder: t("course_form.phone.placeholder"),
      errorMessage: t("course_form.phone.errorMessage"),
      pattern: "^((\\+91-?)|0)?[0-9]{9}$",
      iconImg: phone,
      required: true,
    },
    {
      id: 3,
      name: "email",
      type: "email",
      placeholder: t("course_form.email.placeholder"),
      errorMessage: t("course_form.email.errorMessage"),
      pattern: `/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/`,
      iconImg: mail,
    },
  ];
  const [modalIsOpen, setIsOpen] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  const [isShowPaymentGateWay, setIsShowPaymentGateWay] = useState(false);

  useEffect(() => {
    if(chosenPaymentType) {
      if(chosenPaymentGateWay) {
        const id = parseInt(chosenPaymentGateWay);
        const paymentGW = paymentGateWays.find(e => e.id == id);
        setIsShowPaymentGateWay(paymentGW != null ? paymentGW.attributes.isNeedRedirect : false);
      } else {
        setIsShowPaymentGateWay(false);
      }
    } else {
      setIsShowPaymentGateWay(false);
    }

  }, [chosenPaymentType])

  return (
    <div className="mt-20 register-course">
      <Info
        open={modalIsOpen}
        close={closeModal}
        message={t("info_recieved")}
      />
      <form onSubmit={onHandleSubmit}>
        <div className="form-groups">
          <label>{t("payment_type")}</label>
          <div className="form-groups-w">
            <div className="icon ">
              <div className="img">
                <img src={creditCard} />
              </div>
            </div>
            <div className="select-wrap">
              <select onChange={onPaymentTypeChange} value={chosenPaymentType}>
                {paymentTypes?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.attributes.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        {isShowPaymentGateWay && (
          <div className="form-groups">
            <div className="form-groups-w">
              <label>{t("payment_gateway")}</label>
              <div className="select-wrap">
                <select
                  onChange={onPaymentGateWayChange}
                  value={chosenPaymentGateWay}
                >
                  {paymentGateWays?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.attributes.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        )}

        {inputs.map((input) => (
          <FormInput
            key={input.id}
            {...input}
            value={stateForm[input.name]}
            onChange={handleInputChange}
          />
        ))}
        <div className="form-groups">
          <div className="form-groups-w">
            <textarea
              type="text"
              name="content"
              placeholder={t("course_form.notes.placeholder")}
              value={stateForm.content || ""}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="block-action two-button">
          <div className="item">
            <button type="submit" className="btn btn-primary">
              {t("register_now")}
            </button>
            {/* <Button text={t('register_now')} color_main /> */}
          </div>
          <div className="item">
            <button className="btn btn-primary  grey" onClick={onClose}>
              {t("close")}
            </button>
            {/* <Button grey text={t('close')} /> */}
          </div>
        </div>
      </form>
    </div>
  );
}
