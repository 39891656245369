import React from "react";
import user from "../../assets/images/user.png"
import lock from "../../assets/images/lock.png"
import "./LoginForm.scss";

export default function LoginForm({}) {
  return (
    <div className="">
      <form>
        <div className="form-groups">
          <div className="form-groups-w">
            <input type="text" placeholder="Nhập email hoặc số điện thoại" />
            <div className="icon ">
              <div className="img">
                <img src={user} />
              </div>
            </div>
          </div>
          {/* <div className="error">Lỗi chỗ này</div> */}
        </div>
        <div className="form-groups">
          <div className="form-groups-w">
            <input type="password" placeholder="Nhập mật khẩu" />
            <div className="icon ">
              <div className="img">
                <img src={lock} />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
