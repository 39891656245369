import React from "react";
import "./BlockTitle.scss";

export default function BlockTitle({ label, main, primary, align = "", isBg, size_lg, isNeedHighlight = false }) {
    return (
        <div className={`block-title ${align} ${isBg ? "isbg" : ""} ${size_lg ? "size-lg" : ""}`} >
            <label>{label}</label>
            {
                isNeedHighlight ? <h4 dangerouslySetInnerHTML={{ __html: main }}></h4> : <h4>{main} <span>{primary}</span></h4>
            }
            
        </div>
    );
}
