// import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import Banner from "../../components/Banner/Banner";
import "./CoreTeams.scss";

import { useTranslation } from 'react-i18next';
import { multipleRequest, request, spreadArrayResponse } from '../../helpers/api';
import DOMPurify from "dompurify";
import { LoadingContext } from "../../helpers/loadingContext";
import { useLocomotiveScroll } from "react-locomotive-scroll";
import { useNavigate, useParams } from "react-router-dom";
import { isNeedChangeToUrlLocale, isValidLanguageParam } from "../../helpers/locale";


const CoreTeams = () => {
    const { language } = useParams();
    const navigate = useNavigate();
  
  
    useEffect(() => {
      if (language && isValidLanguageParam(language)) {
        if (isNeedChangeToUrlLocale(language)) {
          i18n.changeLanguage(language);
        }
        return;
      }
      navigate("/", { replace: true });
    }, [language]);
    
    const { i18n } = useTranslation();
    const [coreteamsDetail, setCoreTeamsDeatail] = useState({content:"",blockBannerTitle: "", banner:{data:{attributes:{url:""}} }});

    const [member, setMember] = useState([]);
    const context = useContext(LoadingContext);

    const { scroll } = useLocomotiveScroll()
    useEffect(() => {
        if (scroll != null && scroll != null && member.length > 0) {
            scroll.init();
        }
    }, [scroll, member]);
    
    useEffect(() => {
        context.showLoading();
        multipleRequest([
            request('GET', 'teams', i18n.language, `&sort[0]=createdAt%3Aasc`),
            request('GET', 'configs', i18n.language, `&filters[code][$eq]=core-team`)
        ]).then(spreadArrayResponse((obj1, obj2) => {
            setMember(obj1.data);
            setCoreTeamsDeatail(obj2.data[0].attributes);
            context.hideLoading();
        })).catch(err => {context.showError()});
        
    }, [i18n.language]);


    const createMarkup = (attributes) => {
        let html = "<p></p>";
        if( attributes != null  ) {
            html = DOMPurify.sanitize(attributes.info);
        }
        return {
          __html: html
        };
      }

    return (
        <div className="page core-teams ">
            <div className="ani" data-scroll>
            <Banner label="" main={coreteamsDetail.blockBannerTitle} banner={coreteamsDetail.banner.data.attributes.url} primary="" isNeedHighlight={true} />
            </div>
            <div className="content mt-60 mb-60">
                <div className="content-w size-md">
                    <div className="wrap">
                        <div className="upload-content">
                            <div className="core-team" >
                                {
                                    
                                    member.map(({id, attributes, isFirstItemShowInLeft}) => (
                                        <div key={id} className="item">
                                            <div className={`bl info ani ${isFirstItemShowInLeft ? 'fadeInLeft' : 'fadeInRight'} `} data-scroll>
                                                <div className="avata">
                                                    <span className="aspectRatio aspectRatio--100" style={{ backgroundImage: `url(${attributes.avatar.data.attributes.url})` }}></span>
                                                </div>
                                                <div className="meta ">
                                                    <div className="name mb-10">{attributes.name}</div>
                                                    
                                                    <div className="role" >
                                                        <p dangerouslySetInnerHTML={{ __html: attributes.description }} ></p>
                                                        ​</div>
                                                </div>
                                            </div>
                                            <div className={`bl carreer ani ${isFirstItemShowInLeft? 'fadeInRight' : 'fadeInLeft'}`} data-scroll dangerouslySetInnerHTML={createMarkup(attributes)}>
                                            </div>
                                        </div>
                                    )) 
                                }
                            </div> 

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CoreTeams;
