export class LanguageSupported {
  static EN = new LanguageSupported("en");
  static VI = new LanguageSupported("vi");

  constructor(name) {
    this.name = name;
  }
}

export function getDataFromLocale(locale, attributes) {
  const dataLocalizations = attributes.localizations.data;
  const dataWithLocaleFound = dataLocalizations.find(
    (e) => e.attributes.locale === locale
  );

  return dataWithLocaleFound;
}

export function getPathWithLocaleDataOneLevel(locale, attributes, pathName) {
  let path = "/404";
  const dataWithLocaleFound = getDataFromLocale(locale, attributes);
  if (
    dataWithLocaleFound !== undefined &&
    dataWithLocaleFound !== null &&
    dataWithLocaleFound.id
  ) {
    const localePath = changeLocalePath(pathName, locale);
    path =
      localePath.split("/").slice(0, -1).join("/") +
      `/${dataWithLocaleFound.attributes.slug}`;
  }

  return path;
}

export function getPathWithLocaleDataTwoLevel(locale, attributesLv1, attributesLv2, pathName) {
  let path = "/404";

  const dataWithLocaleFoundLv1 = getDataFromLocale(locale, attributesLv1);
  const dataWithLocaleFoundLv2 = getDataFromLocale(locale, attributesLv2);

  if (
    dataWithLocaleFoundLv1 !== undefined &&
    dataWithLocaleFoundLv1 !== null &&
    dataWithLocaleFoundLv1.id &&
    dataWithLocaleFoundLv2 !== undefined &&
    dataWithLocaleFoundLv2 !== null &&
    dataWithLocaleFoundLv2.id 
  ) {
  
    const localePath = changeLocalePath(pathName, locale);
    path =
    localePath.split("/").slice(0, -2).join("/") +
    `/${dataWithLocaleFoundLv1.attributes.slug}/${dataWithLocaleFoundLv2.attributes.slug}`;
  }

  return path;
}

export function changeLocalePath(pathName, locale) {
  const array = pathName.split("/");
  const dataNeeded = array.slice(2);
  return `/${locale}/${dataNeeded.join("/")}`;
}
