import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import logo2 from "../../assets/images/logo2.png";
import "./Logo.scss";

export default function Logo({}) {
  // const window = document.querySelector("html");
  const window_width = window.innerWidth;
  return (
    <div className="logo">
      <Link to={"./"}>
        <div className="img">
          {
            window_width > "1023" ? <img src={logo} /> : <img src={logo2} />
          }
        </div>
      </Link>
    </div>
  );
}
