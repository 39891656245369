import React, { useEffect, useState } from "react";
import Button from "../Button/Button";
import "./ScheduleItem.scss";
import download from "../../assets/images/download.png";
import locationIcon from "../../assets/images/location.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ScheduleItem({ id, attributes, bgColor, onClick }) {
  const { t } = useTranslation();

  const defaultScheduleDetail = {
    title: "",
    eventTypeName: "",
    day: 0,
    month: 0,
    year: 0,
    category: "",
    linkPdf: "",
    location: "",
    tags: [
      {
        id: 0,
        attributes: {
          textColor: "",
          bgColor: "",
          name: "",
        },
      },
    ],
  };

  const [scheduleItemDetail, setScheduleItemDetail] = useState(
    defaultScheduleDetail
  );

  useEffect(() => {
    if (attributes != null) {
      let newScheduleDetail = defaultScheduleDetail;

      newScheduleDetail.day = attributes.day ?? 0;
      newScheduleDetail.month = attributes.month ?? 0;
      newScheduleDetail.year = attributes.year ?? 0;

      if (attributes.course_template.data != null) {
        newScheduleDetail.title =
          attributes.course_template.data.attributes.name ?? "";

        if (attributes.course_template.data.attributes.brochure.data != null) {
          newScheduleDetail.linkPdf =
            attributes.course_template.data.attributes.brochure.data.attributes
              .url ?? "";
        }

        if (
          attributes.course_template.data.attributes.course_type.data != null
        ) {
          newScheduleDetail.category =
            attributes.course_template.data.attributes.course_type.data
              .attributes.name ?? "";
        }
      }

      if (attributes.event_type != null && attributes.event_type.data != null) {
        newScheduleDetail.eventTypeName =
          attributes.event_type.data.attributes.name ?? "";
      }

      if (attributes.place.data != null) {
        newScheduleDetail.location =
          attributes.place.data.attributes.name ?? "";
      }

      if (attributes.tags.data != null) {
        newScheduleDetail.tags = attributes.tags.data ?? [];
      }

      setScheduleItemDetail(newScheduleDetail);
    }
  }, [attributes]);

  return (
    <div className="schedule-item">
      <div className="wrap">
        <div className="calendar" style={{ backgroundColor: `${bgColor}` }}>
          <div className="c-wrap">
            <div className="day">{scheduleItemDetail.day}</div>
            <div className="month">{scheduleItemDetail.month}</div>
            <div className="year">{scheduleItemDetail.year}</div>
          </div>
        </div>
        <div className="meta">
          <div className="meta-wrap">
            <div className="column ">
              <Link to={`./detail/${id}`} className="title">
                {scheduleItemDetail.title}
              </Link>
              <div className="category">{scheduleItemDetail.category}</div>

              <div className="tags">
                {scheduleItemDetail.tags.map((item) => (
                  <span
                    key={item.id}
                    style={{
                      color: `${item.attributes.textColor}`,
                      backgroundColor: `${item.attributes.bgColor}`,
                    }}
                  >{`#${item.attributes.name}`}</span>
                ))}
              </div>
            </div>
            <div className="column ">
              <div className="location">
                <div className="icon">
                  <div className="img">
                    <img src={locationIcon} />
                  </div>
                </div>
                {scheduleItemDetail.location}
              </div>
              <div className="timer">
                <span>{attributes.timeLearn ?? ""}</span>
              </div>
              <div className="method">
                <span>HỌC {scheduleItemDetail.eventTypeName}</span>
              </div>
            </div>
            <div className="action">
              <a
                href={scheduleItemDetail.linkPdf}
                target="_blank"
                rel="noreferrer"
                className={`download ${scheduleItemDetail.linkPdf ? "" : "disabled"}`}
              >
                <div className="icon">
                  <div className="img">
                    <img src={download} />
                  </div>
                </div>
                <div className="txt">{t("download_brochure")}</div>
              </a>

              <div className="register" onClick={onClick}>
                <Button text={t("register_now")} size_sm color_main />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
