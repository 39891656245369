import React, { useEffect, useState } from "react";
import SwiperCore, { Navigation, Pagination, Autoplay, Parallax } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
// import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import "./BannerSlider.scss";
import { isMobile } from "react-device-detect";
import { useNavigate } from 'react-router-dom';
import { LanguageSupported } from "../../helpers/language";
import { useTranslation } from "react-i18next";

export default function BannerSlider({ onClick, slides }) {
    const navigate = useNavigate();
    const { i18n } = useTranslation();

    const [isLoop, setIsLoop] = useState(false);

    const canNavigate = () => {
        return i18n.language === LanguageSupported.VI.name;
    }

    const navigateTo = () => {
        if (canNavigate()) {
            navigate('./schedules/all');
        }

        return;
    }

    useEffect(() => {
        setIsLoop(false);
        if(slides && slides.length > 1) {
            setIsLoop(true);
        }

    }, [i18n.language, slides])

    // SwiperCore.use([Autoplay])
    SwiperCore.use([Parallax])
    return (
        <div className="banner-slider">
            <Swiper
                slidesPerView={1}
                speed={1000}
                // autoplay={{ delay: 3000 }}
                loop={isLoop}
                parallax={true}
                modules={[Pagination, Navigation]}
                navigation={true}
                pagination={{ clickable: true }}
            >
                {
                    slides.map((item) => (
                        <SwiperSlide key={item.id}>
                            <div onClick={navigateTo} className={`aspectRatio aspectRatio--4-1 slide ${canNavigate() ? 'can-navigate': ''}`} style={{ backgroundImage: `url(${item.attributes[isMobile ? 'coverMobile' : 'cover'].data.attributes.url})` }}>
                                {/* <div className="slide-content">
                                    <label className="mb-20" data-swiper-parallax={-100}>{item.attributes.title}</label>
                                    <div data-swiper-parallax={-200}>
                                        <h4>
                                            {item.attributes.description}
                                        </h4>
                                    </div>
                                    <div className="badge-list ani3 mb-40" data-swiper-parallax={-300}>
                                        {badgeList.map((item) => (
                                            <div className="item" key={item.id} >
                                                <BadgeText label={item.label} icon={item.icon} size="size-md" />
                                            </div>
                                        ))}
                                    </div>
                                    <div data-swiper-parallax={-400}>

                                        <Button link="./schedule" text={t('register_now')} isArrow />
                                    </div>
                                </div> */}
                            </div>
                        </SwiperSlide>
                    ))
                }

            </Swiper>
        </div>
    );
}
