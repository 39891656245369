import "./index.scss";

import {
  BrowserRouter,
  Link,
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import React, { useContext, useEffect, useRef, useState } from "react";

import AboutUs from "./pages/AboutUs/AboutUs";
import Constructor from "./pages/Constructor/Constructor";
import Contact from "./pages/Contact/Contact";
import CoreTeams from "./pages/CoreTeams/CoreTeams";
import CourseDetail from "./pages/CourseDetail/CourseDetail";
import Footer from "./components/Footer/Footer";
import Home from "./pages/Home/Home";
import Info from "./modals/Info";
import { LoadingContext } from "./helpers/loadingContext";
import { LocomotiveScrollProvider } from "react-locomotive-scroll";
import Login from "./modals/Login";
import Logo from "./components/Logo/Logo";
import NavBar from "./components/NavBar/NavBar";
import NavBarTop from "./components/NavBarTop/NavBarTop";
import Page404 from "./pages/Page404/Page404";
import PageComponent from "./pages/PageComponent/PageComponent";
import Partners from "./pages/Partners/Partners";
import { default as PostDetail } from "./pages/PostDetail/PostDetail";
import PostPage from "./pages/PostPage/PostPage";
import Schedule from "./pages/Schedule/Schedule";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import SolutionSystem from "./pages/SolutionSystem/SolutionSystem";
import Solutions from "./pages/Solutions/Solutions";
import iconMenu from "./assets/images/menu-mb.png";
import imgLogo from "./assets/images/logo.png";
import scrollTop from "./assets/images/scrollTop.png";
import { useTranslation } from "react-i18next";

const App = () => {
  const { t, i18n } = useTranslation();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [modalErrorIsOpen, setErrorIsOpen] = useState(false);
  const context = useContext(LoadingContext);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  const openMenu = () => {
    setMenuOpen(true);
  };
  const closeMenu = () => {
    setMenuOpen(false);
  };
  const openErrorModal = () => {
    setErrorIsOpen(true);
  };
  const closeErrorModal = () => {
    context.hideError();
    setErrorIsOpen(false);
  };

  const ref = useRef(null);
  const options = {
    // smooth: true
  };
  const [showTopBtn, setShowTopBtn] = useState(false);
  const pathname = window.location.pathname;

  useEffect(() => {
    if (context.isError) {
      openErrorModal();
    }
    context.hideLoading();
  }, [context.isError]);

  const isLoading = () => {
    setTimeout(function () {
      document.getElementById("loading").classList.add("hide");
      setTimeout(function () {
        document.getElementById("loading").style.display = "none";
        document.body.classList.add("show");
      }, 1000);
    }, 3000);
  };

  const hideLoading = () => {
    document.getElementById("loading").style.display = "flex";
    document.getElementById("loading").classList.remove("hide");
  };
  useEffect(() => {
    if (context.isLoading) {
      isLoading();
    } else {
      hideLoading();
    }
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, [context.isLoading]);
  return (
    <BrowserRouter>
      <Info
        isError={modalErrorIsOpen}
        open={modalErrorIsOpen}
        close={closeErrorModal}
        message={t("error_message")}
      />
      <ScrollToTop />
      {showTopBtn ? (
        <div className="scroll-to" onClick={() => goToTop()}>
          <div className="img">
            <img src={scrollTop} />
          </div>
        </div>
      ) : (
        ""
      )}
      <div id="loading">
        <svg
          id="spill"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
        >
          <circle
            cx="50"
            cy="50"
            r="1"
            fill="none"
            stroke="var(--color-main)"
            strokeWidth="50"
          >
            <animate
              attributeName="r"
              values="10;50"
              dur="1.65s"
              begin="2s"
              repeatCount="1"
              fill="freeze"
            />
          </circle>
        </svg>
        <div className="load-wrap">
          <div className="load-4 logo">
            <div className="img">
              <img className="active" src={imgLogo} />
            </div>
          </div>
        </div>
      </div>

      <div className="toHomepage active" id="toHomepage">
        <Link to={"./"}>{t('back_to_homepage')}</Link>
      </div>
      <Login open={modalIsOpen} close={closeModal} />
      <div className="menu-mb">
        <div className="bl">
          <Logo />
        </div>
        <div className="bl">
          <div className="icon-menu" onClick={() => openMenu()}>
            <div className="img">
              <img src={iconMenu} />
            </div>
          </div>
        </div>
      </div>
      <div className={`page-header ${menuOpen ? "active" : ""}`}>
        <NavBarTop />
        <NavBar onClick={() => openModal()} closeIcon={closeMenu} />
      </div>
      <LocomotiveScrollProvider options={options} containerRef={ref} watch={[]}>
        <div className="container" data-scroll-container ref={ref}>
          <div className="page-content App" data-scroll-section>
            <Routes>
              <Route
                index
                path="/"
                element={<Navigate to={`/${i18n.language}`} />}
              />
              <Route path="/:language">
                <Route index={true} element={<Home />}></Route>
                <Route path="about-us" element={<AboutUs />} />
                <Route path="partners" element={<Partners />} />
                <Route path="solutions" element={<Solutions />} />
                <Route path="core-teams" element={<CoreTeams />} />
                <Route path="page-compt" element={<PageComponent />} />
                <Route
                  path="solution-systems/:slug"
                  element={<SolutionSystem />}
                />

                <Route path="schedules/:cateSlug" element={<Schedule />} />

                <Route
                  path="schedules/:cateSlug/:slug"
                  element={<CourseDetail />}
                />

                <Route path="posts/:cateSlug" element={<PostPage />} />

                <Route path="posts/:cateSlug/:slug" element={<PostDetail />} />

                <Route path="contact" element={<Contact />} />
              </Route>

              <Route path="404" element={<Page404 />} />
              <Route path="*" element={<Page404 />} />
              <Route path="/constructor" element={<Constructor />} />
            </Routes>
            <Footer />
          </div>
        </div>
      </LocomotiveScrollProvider>
    </BrowserRouter>
  );
};

export default App;
