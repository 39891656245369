import React from "react";
import Icon from "../Icon/Icon";
import "./BadgeText.scss";

export default function BadgeText({label, icon , size , isTag = false}) {
    return (
        <div className={`badge text ${size} ${isTag ? "tags" : ""}`}>
            <Icon icon={icon}/>
            <div className="label">
                {label}
            </div>
        </div>
    );
}
