import React from "react";
import "./Approach.scss";
import img1 from "../.././assets/images/1.png";
import img2 from "../.././assets/images/2.png";
import img3 from "../.././assets/images/3.png";
import img4 from "../.././assets/images/4.png";
import step1_idea from "../.././assets/images/step4_idea.webp";
import step2_idea from "../.././assets/images/step2_idea.webp";
import step3_idea from "../.././assets/images/step3_idea.webp";
import step4_idea from "../.././assets/images/step1_idea.webp";
import { useTranslation } from 'react-i18next';

export default function Approach({}) {
  const { t } = useTranslation();

  return (
    <div className="approach">
      <div className="approach-wrap">
        <div className="item mb-20">
          <div className="item-info " style={{ backgroundColor: "#9aca3c" }}>
            <div className="item-info-wrap  aspectRatio aspectRatio--100">
              <div className="inner">
                <div className="item-info-head">
                  <div className="icon">
                    <div className="img">
                      <img src={img4} />
                    </div>
                  </div>
                  <div className="orders">
                    <span>01</span>
                  </div>
                </div>
                <div className="item-info-bottom">
                  <div className="title">{t('approach.item_1.title')}</div>
                  <div className="description">{t('approach.item_1.description')}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-thumb ">
            <span
              className="aspectRatio aspectRatio--100"
              style={{ backgroundImage: `url(${step2_idea})` }}
            ></span>
          </div>
        </div>
        
        <div className="item">
          <div className="item-info" style={{ backgroundColor: "#00BE90" }}>
            <div className="item-info-wrap  aspectRatio aspectRatio--100">
              <div className="inner">
                <div className="item-info-head">
                  <div className="icon">
                    <div className="img">
                      <img src={img2} />
                    </div>
                  </div>
                  <div className="orders">
                    <span>02</span>
                  </div>
                </div>
                <div className="item-info-bottom">
                  <div className="title">{t('approach.item_2.title')}</div>
                  <div className="description">{t('approach.item_2.description')}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-thumb ">
            <span
              className="aspectRatio aspectRatio--100"
              style={{ backgroundImage: `url(${step4_idea})` }}
            ></span>
          </div>
        </div>
        <div className="item mb-20">
          <div className="item-info" style={{ backgroundColor: "#019ADF" }}>
            <div className="item-info-wrap  aspectRatio aspectRatio--100">
              <div className="inner">
                <div className="item-info-head">
                  <div className="icon">
                    <div className="img">
                      <img src={img3} />
                    </div>
                  </div>
                  <div className="orders">
                    <span>03</span>
                  </div>
                </div>
                <div className="item-info-bottom">
                  <div className="title">{t('approach.item_3.title')}</div>
                  <div className="description">{t('approach.item_3.description')}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-thumb ">
            <span
              className="aspectRatio aspectRatio--100"
              style={{ backgroundImage: `url(${step1_idea})` }}
            ></span>
          </div>
        </div>
        <div className="item">
          <div className="item-info" style={{ backgroundColor: "#1f415e" }}>
            <div className="item-info-wrap  aspectRatio aspectRatio--100">
              <div className="inner">
                <div className="item-info-head">
                  <div className="icon">
                    <div className="img">
                      <img src={img1} />
                    </div>
                  </div>
                  <div className="orders">
                    <span>04</span>
                  </div>
                </div>
                <div className="item-info-bottom">
                  <div className="title">{t('approach.item_4.title')}</div>
                  <div className="description">{t('approach.item_4.description')}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-thumb ">
            <span
              className="aspectRatio aspectRatio--100"
              style={{ backgroundImage: `url(${step3_idea})` }}
            ></span>
          </div>
        </div>
      </div>
    </div>
  );
}
