import React, { useContext, useEffect, useState } from "react";
import Banner from "../../components/Banner/Banner";
import DOMPurify from "dompurify";
import "./SolutionSystem.scss";
import PageTitle from "../../components/PageTitle/PageTitle";
import { useTranslation } from "react-i18next";
import { request } from "../../helpers/api";
import { LoadingContext } from "../../helpers/loadingContext";
import { useNavigate, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import Button from "../../components/Button/Button";
import {
  LanguageSupported,
  getPathWithLocaleDataOneLevel,
} from "../../helpers/language";
import { isNeedChangeToUrlLocale, isValidLanguageParam } from "../../helpers/locale";

const SolutionSystem = () => {
  const navigate = useNavigate();

  const { i18n, t } = useTranslation();
  const { language, slug } = useParams();
  useEffect(() => {
    if (language && isValidLanguageParam(language)) {
      if (isNeedChangeToUrlLocale(language)) {
        i18n.changeLanguage(language);
      }
      return;
    }
    navigate("/", { replace: true });
  }, [language]);


  const pathName = useLocation().pathname;
  const navigateTo = () => {
    navigate(`../schedules/${cateDetail.course_type.data.attributes.slug}`);
  };

  const [isShowBtnRegister, setIsShowBtnRegister] = useState(false);

  const defaultCateDetailState = {
    name: "",
    content: "",
    blockBannerTitle: "",
    locale: "",
    banner: { data: { attributes: { url: "" } } },
    course_type: {
      data: {
        id: 0,
      },
    },
  };
  const [cateDetail, setCateDetail] = useState(defaultCateDetailState);
  const context = useContext(LoadingContext);

  useEffect(() => {
    setIsShowBtnRegister(LanguageSupported.VI.name === i18n.language);

    if (cateDetail && cateDetail.name) {
      if (cateDetail.locale !== i18n.language) {
        const pathRes = getPathWithLocaleDataOneLevel(
          i18n.language,
          cateDetail,
          pathName
        );
        setCateDetail(defaultCateDetailState);
        navigate(pathRes);
        return;
      }
    }

    context.showLoading();
    request(
      "GET",
      `solution-systems`,
      i18n.language,
      `&filters[slug][$eq]=${slug}`
    )
      .then((response) => {
        const data = response.data[0];
        setCateDetail(data.attributes);
        context.hideLoading();
      })
      .catch((err) => {
        context.showError();
      });
  }, [slug, i18n.language]);

  function createMarkup() {
    return {
      __html: DOMPurify.sanitize(cateDetail == null ? "" : cateDetail.content),
    };
  }

  return (
    <div className="page solution-system">
      <div className="ani" data-scroll>
        <Banner
          label=""
          main={cateDetail.blockBannerTitle}
          banner={cateDetail.banner.data.attributes.url}
          primary=""
          isNeedHighlight={true}
        />
      </div>
      <div className="content mt-60 mb-60">
        <div className="content-w size-md">
          <div className="wrap">
            <div className="mb-40 ani fadeInUp" data-scroll>
              <PageTitle title={cateDetail.name} isDangerousHTML={true} />
            </div>
            <div className="upload-content ani fadeInUp" data-scroll>
              <div dangerouslySetInnerHTML={createMarkup()}></div>
            </div>
          </div>
        </div>
        {isShowBtnRegister &&
          cateDetail.course_type.data != null &&
          cateDetail.course_type.data.id > 0 && (
            <div onClick={navigateTo} className=" mt-40 btn-link-to-schedule">
              <Button text={t("register_now")} color_main />
            </div>
          )}
      </div>
    </div>
  );
};

export default SolutionSystem;
