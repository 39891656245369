import {useState, createContext} from 'react'

const LoadingContext = createContext();

function LoadingProvider({children}) {
    const [isFirstLoad, setIsFirstLoad] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    const showError = () => {
        setIsError(true);
    }

    const hideError = () => {
        setIsError(false);
    }

    const showLoading = () => {
        if(!isFirstLoad){
            setIsFirstLoad(true);
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }

    const hideLoading = () => {
        setIsLoading(false);
    }

    const value = {
        isLoading,
        showLoading,
        hideLoading,
        isError,
        showError,
        hideError
    }

    return (
        <LoadingContext.Provider value={value}>
            {children}
        </LoadingContext.Provider>
    )
}

export {LoadingContext, LoadingProvider}