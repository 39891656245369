import React from "react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/react";
import Card from "../Card/Card";
import "./NewsSlider.scss";


export default function NewsSlider({ cardList, isRelatedLink = false, isUsingFullPathNews = false }) {
  return (
    <div className="news-slider">
      <Swiper
        slidesPerView={3}
        spaceBetween={40}
        speed={1000}
        modules={[Pagination, Navigation]}
        navigation={{ clickable: true }}
        pagination={{ clickable: true }}
        breakpoints={{
          1360: {
            spaceBetween: 40
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 20
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          320: {
            slidesPerView: 1.3,
            spaceBetween: 20
          },

        }}
      >
        {cardList.map((item) => (
          <SwiperSlide key={item.id}>
            <Card
              id={item.id}
              title={item.attributes.title}
              time={item.attributes.createdAt}
              thumb={item.attributes.cover.data.attributes.formats.thumbnail.url}
              excerpt={item.attributes.shortDescription}
              isRelatedLink={isRelatedLink}
              slug={item.attributes.slug}
              isUsingFullPathNews={isUsingFullPathNews}
              cateID={item.attributes.post_type.data.id}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
