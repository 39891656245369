import React from "react";
import NewsSlider from "../NewsSlider/NewsSlider";
import "./RelatedPost.scss";
import { useTranslation } from "react-i18next";

export default function RelatedPost({ posts }) {
  const {t} = useTranslation();
  return (
    <div className="related-post">
      <label>{t('realted_posts.title')}</label>
      <div className="list">
        <NewsSlider cardList={posts} isRelatedLink={true} />
      </div>
    </div>
  );
}
