import React from "react";
import "./Constructor.scss";
import img from "../../assets/images/contructor.png"
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Constructor = () => {
  const { t } = useTranslation();
  return (
    <div className="page constructor">

      <div className="content mt-60 mb-60">
        <div className="content-w size-sm">
          <div className="wrap">
            <div className="img mb-40">
              <img src={img} />
            </div>
            <div className="text">{t('implementing')}</div>
            <div className="action mt-40">
              <Link to={".."}>{t('404page.back_main_page')}</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Constructor;
