import React, { useContext, useEffect, useState } from "react";
import icon3 from "../../assets/images/s10.png";
import icon1 from "../../assets/images/s8.png";
import icon2 from "../../assets/images/s9.png";

import Banner from "../../components/Banner/Banner";
import Feature from "../../components/Feature/Feature";
import PageTitle from "../../components/PageTitle/PageTitle";
import "./Solutions.scss";

import { useTranslation } from "react-i18next";
import { multipleRequest, request, spreadArrayResponse } from "../../helpers/api";
import { LoadingContext } from "../../helpers/loadingContext";
import { useNavigate, useParams } from "react-router-dom";
import { isNeedChangeToUrlLocale, isValidLanguageParam } from "../../helpers/locale";


const Solutions = () => {
    const { language } = useParams();
    const navigate = useNavigate();
  
  
    useEffect(() => {
      if (language && isValidLanguageParam(language)) {
        if (isNeedChangeToUrlLocale(language)) {
          i18n.changeLanguage(language);
        }
        return;
      }
      navigate("/", { replace: true });
    }, [language]);
    const { t, i18n } = useTranslation();

    const featureList = [
        {
            id: 1,
            icon: icon1,
            title: "our_solutions.block_1.sub_1.title",
            description: "our_solutions.block_1.sub_1.description",
        },
        {
            id: 2,
            icon: icon2,
            title: "our_solutions.block_1.sub_2.title",
            description: "our_solutions.block_1.sub_2.description",
        },
        {
            id: 3,
            icon: icon3,
            title: "our_solutions.block_1.sub_3.title",
            description: "our_solutions.block_1.sub_3.description",
        },
    ];

    const [solutions, setSolutions] = useState([{ id: 0,
        attributes: {
            name: '', description: '', 
            icon: {
                data: {
                    attributes: { icon: { data : {attributes: {url: ""}}}}}
                }
        }
    }]);

    const [solutionDetail, setSolutionDeatail] = useState({
        content: "",
        blockBannerTitle: "",
        banner: { data: { attributes: { url: "" } } },
    });

    const context = useContext(LoadingContext);

    useEffect(() => {
        context.showLoading();
        multipleRequest([
            request('GET', 'main-solutions', i18n.language, `&sort[0]=id%3Aasc`),
            request('GET', 'configs', i18n.language, `&filters[code][$eq]=solution`)
        ]).then(spreadArrayResponse((obj1, obj2) => {
            setSolutions(obj1.data);
            setSolutionDeatail(obj2.data[0].attributes);
            context.hideLoading();
        })).catch(err => {context.showError()});

      },[i18n.language]);


    return (
        <div className="page solution ">
            <div className="ani" data-scroll>
                <Banner
                    label=""
                    main={solutionDetail.blockBannerTitle}
                    banner={solutionDetail.banner.data.attributes.url}
                    primary=""
                    isNeedHighlight={true}
                />
            </div>
            <div className="content mt-60 mb-60">
                <div className="content-w size-md">
                    <div className="wrap">
                        <div className=" mb-40 ani fadeInUp" data-scroll>
                            <PageTitle title={t("our_solutions.block_1.title")} />
                        </div>
                        <div className="upload-content">
                            <div className="solution-list mt-60 mb-20 ani fadeInUp" data-scroll>
                                {featureList.map((item) => (
                                    <div className="item" key={item.id}>
                                        <Feature icon={item.icon} isDangerousHTML={true} title={t(item.title)} description={t(item.description)} />
                                    </div>
                                ))}
                            </div>
                            {/* <div className="titlebar fadeInUp ani" data-scroll>
                                <div className="img">
                                    <img src={titleBar} />
                                </div>
                            </div> */}
                            <div className=" mb-40 ani fadeInUp" data-scroll>
                                <PageTitle title={t('our_solutions.block_2.title')} />
                            </div>
                            <div className="badge-list center">
                                {/* {badgeList.map((item) => (
                                    <div className="item">
                                        <BadgeText label={item.label} icon={item.icon} size="size-md" />
                                    </div>
                                ))} */}
                                {
                                    solutions.map((item) => (
                                        <div key={item.id} className="item fadeInUp ani " data-scroll>
                                            <div className="icon">
                                                <div className="img">
                                                    <img src={`${item.attributes.icon.data.attributes.url}`} />
                                                </div>
                                            </div>
                                            <div className=" meta">
                                                <div className="label">{item.attributes.name}</div>
                                                <div className="description">
                                                    {item.attributes.description}
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Solutions;
