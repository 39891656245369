import { randomNumberInRange } from "./number";

const arrColors = ["#64CCC9", "#73CC64", "#CC9064", "#CC6464", "#BDCC64"];

function randomColor() {
  return arrColors[randomNumberInRange(0, arrColors.length - 1)];

}

function getColorByIndex(index) {

  if(index > arrColors.length) {
    index = arrColors.length - 1;
  }

  return arrColors[index];
}

export {randomColor, getColorByIndex}