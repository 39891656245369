import React from "react";
import { Link } from "react-router-dom";
import "./Button.scss";
import arrow from "../../assets/images/right-arrow.png";

export default function Button({ className = "button", link, text, isArrow, size_sm, color_main, grey }) {
  return (
    <div className="button">
      <Link to={link} className={`txt ${isArrow ? "isArrow" : ""} ${size_sm ? "size-sm" : ""} ${color_main ? "color-main" : ""} ${grey ? "grey" : ""}`}>
        {text}
        <div className="arrow">
          <div className="img">
            <img src={arrow} />
          </div>
        </div>
      </Link>
    </div>
  );
}
