import { useState } from "react";
import "./FormInput.scss";

const FormInput = (props) => {
  const [focused, setFocused] = useState(false);
  const { label, errorMessage, onChange, id, iconImg, ...inputProps } = props;

  const handleFocus = (e) => {
    setFocused(true);
  };

  return (
    <div className="form-groups">
      <div className="form-groups-w">
      {iconImg && 
        <div className="icon ">
          <div className="img">
            <img src={iconImg} />
          </div>
        </div>
      }
        <input
          {...inputProps}
          onChange={onChange}
          onBlur={handleFocus}
          onFocus={() =>
            inputProps.name === "confirmPassword" && setFocused(true)
          }
          focused={focused.toString()}
        />
        <span className="error">{errorMessage}</span>
      </div>
    </div>
  );
};

export default FormInput;
