import React from "react";
import "./modals.scss";
import Modal from "react-modal";
import Button from "../components/Button/Button";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

export default function Info({ open, close, message, isError = false }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onClickClose = () => {
    close();

    if(isError) {
      navigate('../constructor');
    }
  }
  return (
    <div>
      <Modal ariaHideApp={false} isOpen={open} contentLabel="Example Modal" className="block-modal">
        <div className="modal-close" onClick={close}>
          <div className="img"></div>
        </div>
        <div className="modal-content mb-20">
          <div className="modal-title">{t('info')}</div>
          <div className="text">
            <p > {message}</p>
          </div>
        </div>
        <div className="block-action ">
        
          <div onClick={onClickClose} className="one-item">
            <Button grey text={t('close')} />
          </div>
        </div>
      </Modal>
    </div>
  );
}
