import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import "./assets/scss/view.scss";
import reportWebVitals from "./reportWebVitals";
import App from "./app";
import './helpers/i18n';
import { LoadingProvider } from "./helpers/loadingContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
      <LoadingProvider>
        <App />
      </LoadingProvider>
    </React.StrictMode>
);
reportWebVitals();
