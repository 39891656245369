import React from "react";
import "./modals.scss";
import Modal from "react-modal";
import Button from "../components/Button/Button";
import RegiterCourseForm from "../components/RegiterCourseForm/RegiterCourseForm";

import cate from "../assets/images/categories.png";
import clock from "../assets/images/clock.png";
import location from "../assets/images/location.png";
import knowledge from "../assets/images/knowledge.png";
import s1 from "../assets/images/s1.png";

import { useTranslation } from "react-i18next";
import BadgeText from "../components/BadgeText/BadgeText";


export default function RegiterCourse({ data, close }) {
  const { t } = useTranslation();
  return (
    <div>
      <Modal ariaHideApp={false} isOpen={data.isOpen} contentLabel="Example Modal" className="block-modal">
        <div className="modal-close" onClick={close}>
          <div className="img"></div>
        </div>
        <div className="modal-content mb-20">
          <div className="modal-title size-sm">{t("schedule.register_course_title")}</div>
          <div className="modal-title">{data.dataDetail.course_template.data.attributes.name}</div>
          <div className="meta">
            <div >
              <BadgeText 
                label= {data.dataDetail.course_template.data.attributes.course_type?.data?.attributes?.name} 
                icon={cate} 
                size="size-sm" />
            
                  <BadgeText 
              label={data.dataDetail.event_type?.data?.attributes?.name} 
              icon={knowledge} 
              size="size-sm" />
            <BadgeText 
              label={data.dataDetail.place?.data?.attributes?.name} 
              icon={location} 
              size="size-sm" />
            </div>
            <div >
            <BadgeText 
              label={`${data.dataDetail.timeLearn}`} 
              icon={clock} 
              size="size-sm" />
            <BadgeText 
                label={`${t('course_details.start_from')} ${data.dataDetail.hour || "00"}:${ data.dataDetail.minute || "00"}`}
                icon={clock} 
                size="size-sm" />
              <BadgeText 
                label={`${data.dataDetail.dateLearn}`} 
                icon={clock} 
                size="size-sm" />
            </div>
          </div>
          {
            data.dataDetail.course_template.data.attributes.name && <RegiterCourseForm data={data.dataDetail} onClose={close} />
          } 
         
        </div>
      </Modal>
    </div>
  );
}
