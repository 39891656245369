// import axios from "axios";
import DOMPurify from "dompurify";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import banner from "../../assets/images/Banner_news.jpg";
import icon1 from "../../assets/images/calendar-primary.png";
import zalo from "../../assets/images/zalo.png";
import Banner from "../../components/Banner/Banner";
import PageTitle from "../../components/PageTitle/PageTitle";
import RelatedPost from "../../components/RelatedPost/RelatedPost";
import "./PostDetail.scss";
import { useTranslation } from "react-i18next";
import {
  multipleRequest,
  request,
  spreadArrayResponse,
} from "../../helpers/api";
import { LoadingContext } from "../../helpers/loadingContext";
import SocialSharing from "../../components/SocialSharing/SocialSharing";
import { useLocation } from "react-router-dom";
import {
  isNeedChangeToUrlLocale,
  isValidLanguageParam,
} from "../../helpers/locale";
import { getPathWithLocaleDataTwoLevel } from "../../helpers/language";

const PostDetail = () => {
  const navigate = useNavigate();
  let { language, cateSlug, slug } = useParams();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (language && isValidLanguageParam(language)) {
      if (isNeedChangeToUrlLocale(language)) {
        i18n.changeLanguage(language);
      }
      return;
    }
    navigate("/", { replace: true });
  }, [language]);

  const defaultCardDetailState = {
    id: 0,
    post_type: { data: { id: 0, attributes: { blockBannerTitle: "" } } },
    content: "",
    locale: "",
    name: "",
    cover:{data: {attributes: {url: "" }}}
  };

  const [cardDetail, setCardDetail] = useState(defaultCardDetailState);
  const defaultPostTypeState = {
    name: "",
    locale: "",
    banner: { data: { attributes: { url: "" } } },
  };
  const [postType, setPostType] = useState(defaultPostTypeState);
  const [cardRelated, setcardRelated] = useState([]);
  const context = useContext(LoadingContext);
  const pathName = useLocation().pathname;

  useEffect(() => {
    if (cardDetail && cardDetail.title && postType && postType.name) {
      if (cardDetail.locale !== i18n.language) {
        const pathRes = getPathWithLocaleDataTwoLevel(
          i18n.language,
          postType,
          cardDetail,
          pathName
        );
        setCardDetail(defaultCardDetailState);
        setPostType(defaultPostTypeState);
        navigate(pathRes);
        return;
      }
    }

    if (slug) {
      context.showLoading();
      request(
        "GET",
        `posts`,
        i18n.language,
        `&filters[slug][$eq]=${slug}&filters[post_type][slug][$eq]=${cateSlug}`
      )
        .then((response) => {
          const arrData = response.data;
          const data = arrData[0].attributes;

          setCardDetail(data);
          context.hideLoading();
        })
        .catch((err) => {
          context.showError();
        });
    }
  }, [i18n.language, slug]);

  useEffect(() => {
    if (cardDetail) {
      multipleRequest([
        request(
          "GET",
          "posts",
          i18n.language,
          `&filters[post_type][id][$eq]=${cardDetail.post_type.data.id}`
        ),
        request(
          "GET",
          `post-types`,
          i18n.language,
          `&filters[slug][$eq]=${cateSlug}`
        ),
      ])
        .then(
          spreadArrayResponse((obj1, obj2) => {
            setcardRelated(
              obj1.data.filter(
                ({ attributes }) => attributes.slug != cardDetail.slug
              )
            );
            setPostType(obj2.data[0].attributes);
            context.hideLoading();
          })
        )
        .catch((err) => {
          context.showError();
        });
    }
  }, [cardDetail]);

  function createMarkup() {
    return {
      __html: DOMPurify.sanitize(cardDetail == null ? "" : cardDetail.content),
    };
  }

  const dateConverted = (date) => {
    return date.toLocaleString(i18n.language, {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <div className="page news-event-detail">
      <Banner
        label={""}
        main={
          cardDetail == null
            ? ""
            : cardDetail.post_type.data.attributes.blockBannerTitle
        }
        primary=""
        isNeedHighlight={true}
        banner={cardDetail.cover.data.attributes.url?? postType.banner.data.attributes.url}
      />
      <div className="content mt-60 mb-60">
        <div className="content-w size-sm">
          <div className="wrap">
            <div className="head  mb-60">
              <div className="mb-30">
                <PageTitle title={cardDetail == null ? "" : cardDetail.title} />
              </div>
              <div className="meta">
                <div className="post-info">
                  <div className="icon">
                    <div className="img">
                      <img src={icon1} />
                    </div>
                  </div>
                  <div className="time">
                    {t("post_details.last_edited")}
                    <span>
                      {cardDetail == null
                        ? ""
                        : dateConverted(new Date(cardDetail.createdAt))}
                    </span>
                  </div>
                </div>
                <div className="share">
                  {t("post_details.share")}
                  <div className="social">
                    {/* <div className="icon">
                      <div className="img">
                        <img src={zalo} />
                      </div>
                    </div> */}
                    <SocialSharing />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="upload-content"
              dangerouslySetInnerHTML={createMarkup()}
            ></div>
            {cardRelated && cardRelated.length > 0 && (
              <div className="mt-60">
                <RelatedPost posts={cardRelated} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostDetail;
