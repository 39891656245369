// import axios from "axios";
import { gsap, Power1 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { useEffect, useRef, useState, useContext } from "react";
import { LoadingContext } from "../../helpers/loadingContext";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import icon from "../../assets/images/checkmark.png";
import iconCoop from "../../assets/images/collaboration.png";
import part1 from "../../assets/images/partner1.png";
import part2 from "../../assets/images/partner2.png";
import prize1 from "../../assets/images/prize1.png";
import prize2 from "../../assets/images/prize2.png";
import icon1 from "../../assets/images/s1.png";
import icon2 from "../../assets/images/s2.png";
import icon3 from "../../assets/images/s3.png";
import icon4 from "../../assets/images/s4.png";
import icon5 from "../../assets/images/s5.png";
import icon6 from "../../assets/images/s6.png";
import icon7 from "../../assets/images/s7.png";
import Approach from "../../components/Approach/Approach";
import BannerSlider from "../../components/BannerSlider/BannerSlider";
import BlockTitle from "../../components/BlockTitle/BlockTitle";
import Feature from "../../components/Feature/Feature";
import Member from "../../components/Member/Member";
import {
  multipleRequest,
  request,
  spreadArrayResponse,
} from "../../helpers/api";
import RegiterAccount from "../../modals/RegiterAccount";

import { useTranslation } from "react-i18next";
import "./Home.scss";
import { useNavigate, useParams } from "react-router";
import {
  isNeedChangeToUrlLocale,
  isValidLanguageParam,
} from "../../helpers/locale";

function Home() {
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  const { language } = useParams();
  useEffect(() => {
    if (language && isValidLanguageParam(language)) {
      if (isNeedChangeToUrlLocale(language)) {
        i18n.changeLanguage(language);
      }
      return;
    }
    navigate("/", { replace: true });
  }, [language]);

  const featureList = [
    {
      id: 1,
      icon: icon1,
      title: t("about_us.banner.title1"),
      description: t("about_us.banner.description1"),
    },
    {
      id: 2,
      icon: icon2,
      title: t("about_us.banner.title2"),
      description: t("about_us.banner.description2"),
    },
    {
      id: 3,
      icon: icon3,
      title: t("about_us.banner.title3"),
      description: t("about_us.banner.description3"),
    },

    {
      id: 4,
      icon: icon4,
      title: t("about_us.banner.title4"),
      description: t("about_us.banner.description4"),
    },
    {
      id: 5,
      icon: icon5,
      title: t("about_us.banner.title5"),
      description: t("about_us.banner.description5"),
    },
    {
      id: 6,
      icon: icon6,
      title: t("about_us.banner.title6"),
      description: t("about_us.banner.description6"),
    },
    {
      id: 7,
      icon: icon7,
      title: t("about_us.banner.title7"),
      description: t("about_us.banner.description7"),
    },
  ];
  const context = useContext(LoadingContext);
  const [modalIsOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  const [member, setMember] = useState([]);
  const [banner, setBanner] = useState([]);
  const [customers, setCustomers] = useState([]);
  const pathname = window.location.pathname;

  useEffect(() => {
    context.showLoading();
    multipleRequest([
      request("GET", "customers", i18n.language),
      request("GET", "home-banners", i18n.language),
      request("GET", "teams", i18n.language, `&sort[0]=createdAt%3Aasc`),
    ])
      .then(
        spreadArrayResponse((obj1, obj2, obj3) => {
          setCustomers(obj1.data);
          setBanner(obj2.data);
          setMember(obj3.data);
          context.hideLoading();
        })
      )
      .catch((err) => {
        context.showError();
      });

    const header = document.querySelector(".toHomepage");
    if (location.pathname == "/") {
      header.classList.remove("active");
    }
    return () => {
      header.classList.add("active");
    };
  }, [i18n.language]);

  gsap.registerPlugin(ScrollTrigger);
  const ref = useRef(null);

  useEffect(() => {
    const element = ref.current;

    gsap.fromTo(
      [
        element.querySelector(".list-services .item:nth-child(1) "),
        element.querySelector(".list-services .item:nth-child(2) "),
        element.querySelector(".list-services .item:nth-child(3) "),
        element.querySelector(".list-services .item:nth-child(4) "),
        element.querySelector(".list-services .item:nth-child(5) "),
        element.querySelector(".list-services .item:nth-child(6) "),
        element.querySelector(".list-services .item:nth-child(7) "),
      ],
      {
        opacity: 0,
        y: 20,
      },
      {
        opacity: 1,
        y: 0,
        ease: Power1.ease,
        duration: 0.7,
        stagger: 0.05,
        scrollTrigger: {
          trigger: element.querySelector(".list-services"),
          start: "top 70%",
          end: "bottom center",
          // markers: true,
        },
      }
    );
    gsap.fromTo(
      [
        element.querySelector(".s2 .item:nth-child(1)"),
        element.querySelector(".s2 .item:nth-child(2)"),
        element.querySelector(".s2 .item:nth-child(3)"),
        element.querySelector(".s2 .item:nth-child(4)"),
      ],
      {
        opacity: 0,
        scale: 0.8,
      },
      {
        opacity: 1,
        ease: Power1.ease,
        duration: 0.5,
        stagger: 0.2,
        scale: 1,
        scrollTrigger: {
          trigger: element.querySelector(".s2 .approach"),
          start: "top 50%",
          end: "bottom center",
          // markers: true,
          onEnter: () => {
            element.querySelector(".s2 .approach").classList.add("active");
          },
        },
      }
    );
  }, []);
  return (
    <>
      <RegiterAccount open={modalIsOpen} close={closeModal} />
      <div className="page home" ref={ref}>
        <div className="section s1">
          <BannerSlider onClick={() => openModal()} slides={banner} />
        </div>
        <div className="section s2">
          <div className="content-w size-lg">
            <div className="mb-50 ">
              <BlockTitle
                main={t("home.plan.primary")}
                primary={""}
                isNeedHighlight={true}
              />
            </div>
            <Approach />
          </div>
        </div>
        <div className="section s3">
          <div className="content-w size-lg">
            <div className="mb-50 ">
              <BlockTitle
                main={t("core_team_title")}
                primary={""}
                align="center"
                isBg
                isNeedHighlight={true}
              />
            </div>
            <div className="core-team ">
              {member?.map((item) => (
                <div className="item" key={item.id}>
                  <Member
                    id={item.id}
                    isSquare={false}
                    name={item.attributes.name}
                    role={item.attributes.description}
                    avata={item.attributes.avatar.data.attributes.url}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className=" section core-partner">
          <div className="content-w size-lg">
            <div className="mb-50 ">
              <BlockTitle
                main={t("home.partner.title")}
                primary={""}
                align="center"
                isNeedHighlight={true}
              />
            </div>
            <div className="list">
              <div className="item main ">
                <div className="logo">
                  <div className="img">
                    <img src={part2} />
                  </div>
                </div>
                <div className="description">
                  {t("home.partner.block_1.main_description")}
                </div>
                <div className="prize">
                  <div className="img">
                    <img src={prize2} />
                  </div>
                </div>
                <div className="info p1">
                  <div className="wrap">
                    <div className="info-item">
                      <div className="inner">
                        <div className="no">50+</div>
                        <div className="value uppercasetext">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: t("home.partner.block_1.sub_1"),
                            }}
                          ></span>
                        </div>
                      </div>
                    </div>
                    <div className="info-item">
                      <div className="inner">
                        <div className="no">7</div>
                        <div className="value uppercasetext">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: t("home.partner.block_1.sub_2"),
                            }}
                          ></span>
                        </div>
                      </div>
                    </div>
                    <div className="info-item">
                      <div className="inner">
                        <div className="no">20</div>
                        <div className="value uppercasetext">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: t("home.partner.block_1.sub_3"),
                            }}
                          ></span>
                        </div>
                      </div>
                    </div>
                    <div className="info-item">
                      <div className="inner">
                        <div className="no">27K</div>
                        <div className="value uppercasetext">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: t("home.partner.block_1.sub_4"),
                            }}
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item ">
                <div className="icon">
                  <div className="img">
                    <img src={iconCoop} />
                  </div>
                </div>
              </div>
              <div className="item main   ">
                <div className="logo">
                  <div className="img">
                    <img src={part1} />
                  </div>
                </div>
                <div className="description">
                  {t("home.partner.block_2.main_description")}
                </div>
                <div className="prize">
                  <div className="img">
                    <img src={prize1} />
                  </div>
                </div>
                <div className="info p2">
                  <div className="wrap">
                    <div className="info-item">
                      <div className="title">
                        {t("home.partner.block_2.sub_1.title")}
                      </div>
                      <div className="value">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: t("home.partner.block_2.sub_1.description"),
                          }}
                        ></span>
                      </div>
                    </div>
                    <div className="info-item">
                      <div className="title">
                        {t("home.partner.block_2.sub_2.title")}{" "}
                      </div>
                      <div className="value">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: t("home.partner.block_2.sub_2.description"),
                          }}
                        ></span>
                      </div>
                    </div>
                    <div className="info-item">
                      <div className="title">
                        {t("home.partner.block_2.sub_3.title")}{" "}
                      </div>
                      <div className="value">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: t("home.partner.block_2.sub_3.description"),
                          }}
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section customer">
          <div className="content-w size-lg">
            <div className="mb-50 ">
              <BlockTitle
                main={t("customer_title")}
                primary={""}
                align="center"
                isBg
                isNeedHighlight={true}
              />
            </div>
            <div className="list">
              {customers.map((item) => (
                <div className="item" key={item.id}>
                  <div className="img logo">
                    <img src={item.attributes.cover.data.attributes.url} />
                  </div>
                  <div className="overlay">
                    <span>{item.attributes.title}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="section s4">
          <div className="content-w size-lg">
            <div className="mb-50 ">
              <BlockTitle
                main={t("home.block_4.primary_title")}
                primary={""}
                align="center"
                isNeedHighlight={true}
                // label={t("home.block_4.label")}
              />
            </div>

            <div className="list-services">
              {featureList.map((item) => (
                <div className="item" key={item.id}>
                  <Feature
                  isDangerousHTML={true}
                    icon={item.icon}
                    title={item.title}
                    description={item.description}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
