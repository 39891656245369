import { Pagination } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import banner from "../../assets/images/Banner_news.jpg";
import Banner from "../../components/Banner/Banner";
import Card from "../../components/Card/Card";
import "./PostPage.scss";
import { useNavigate } from "react-router-dom";
import { request } from "../../helpers/api";
import { LoadingContext } from "../../helpers/loadingContext";
import { isNeedChangeToUrlLocale, isValidLanguageParam } from "../../helpers/locale";
import { getPathWithLocaleDataOneLevel } from "../../helpers/language";
import { useLocation } from "react-router-dom";

const PostPage = () => {
  const { language, cateSlug } = useParams();
  const pathName = useLocation().pathname;
  const navigate = useNavigate();


  useEffect(() => {
    if (language && isValidLanguageParam(language)) {
      if (isNeedChangeToUrlLocale(language)) {
        i18n.changeLanguage(language);
      }
      return;
    }
    navigate("/", { replace: true });
  }, [language]);

  const defaultCateDetailState = {
    attributes: {
      name: "",
      banner: {
        data: {
          attributes: {
            url: "",
          },
        },
      },
    },
  };
  
  const context = useContext(LoadingContext);
  const [cateID, setCateID] = useState(0);
  const [pagination, setPagingInfo] = useState({
    page: 1,
    pageSize: 9,
    pageCount: 0,
    total: 0,
  });

  const setDefaultData = () => {
    setPagingInfo({
      page: 1,
      pageSize: 9,
      pageCount: 0,
      total: 0,
    });
  };

  const getCateQuery = () => {
    return cateID != null && !isNaN(cateID)
      ? `&filters[post_type][id][$eq]=${cateID}`
      : "";
  };

  const getPaginationQuery = () => {
    return `&pagination[pageSize]=${pagination.pageSize}&pagination[page]=${pagination.page}`;
  };

  const handlePaginationChange = (event, value) => {
    if (value) {
      setPagingInfo({ ...pagination, page: value });
    }
  };

  const { i18n, t } = useTranslation();

  const [postType, setPostType] = useState(defaultCateDetailState);

  const [cards, setCards] = useState([]);

  const getPosts = () => {
    request(
      "GET",
      "posts",
      i18n.language,
      `${getCateQuery()}${getPaginationQuery()}`
    ).then((response) => {
      const arrData = response.data;
      setPagingInfo(response.meta.pagination);
      setCards(arrData);
    });
  };

  useEffect(() => {

    if (postType && postType.attributes.name) {
      const {attributes} = postType;
      if (attributes.locale !== i18n.language) {
        const pathRes = getPathWithLocaleDataOneLevel(
          i18n.language,
          attributes,
          pathName
        );
        setPostType(defaultCateDetailState);
        setCards([]);
        setDefaultData();
        navigate(pathRes);
        return;
      }
    }

    if (cateSlug) {
      context.showLoading();

      request(
        "GET",
        `post-types`,
        i18n.language,
        `&filters[slug][$eq]=${cateSlug}`
      )
        .then((response) => {
          const data = response.data[0];
          if (data) {
            setCateID(data.id);
            setDefaultData();
            setPostType(data);
            context.hideLoading();

          }
        })
        .catch((err) => {
          context.showError();
        });
    }
  }, [i18n.language, cateSlug]);

  useEffect(() => {
    getPosts();
  }, [pagination.page, cateID]);

  return (
    <div className="page news-event list-card">
      <div className="ani" data-scroll>
        <Banner
          label=""
          main={postType.attributes.blockBannerTitle}
          banner={
            postType.attributes.banner.data != null
              ? postType.attributes.banner.data.attributes.url
              : banner
          }
          primary=""
          isNeedHighlight={true}
        />
      </div>
      <div className="content mt-60 mb-60">
        <div className="content-w size-md">
          <div className="wrap">
            <div className="list ani fadeInUp" data-scroll>
              {cards.map((item) => (
                <div className="item" key={item.id}>
                  <Card
                    id={item.id}
                    slug={item.attributes.slug}
                    cateSlug={cateSlug}
                    title={item.attributes.title}
                    time={item.attributes.createdAt}
                    thumb={
                      item.attributes.cover?.data?.attributes?.formats
                        ?.small?.url
                    }
                    excerpt={item.attributes.shortDescription}
                  />
                </div>
              ))}
            </div>
            {!pagination.total && (
              <div className=" mt-40 block-title">
                {" "}
                <label>{t("post.no_post_message")}</label>
              </div>
            )}
            {pagination.pageCount > 1 && (
              <div className=" mt-40 block-title">
                <Pagination
                  page={pagination.page}
                  onChange={handlePaginationChange}
                  count={pagination.pageCount}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostPage;
