import React from "react";
import user from "../../assets/images/user.png"
import lock from "../../assets/images/lock.png"
import phone from "../../assets/images/phone.png"
import mail from "../../assets/images/mail.png"
import "./RegiterAccountForm.scss";

export default function RegiterAccountForm({}) {
  return (
    <div className="">
      <form>
        <div className="form-groups">
          <div className="form-groups-w">
            <input type="text" placeholder="Họ và tên" />
            <div className="icon ">
              <div className="img">
                <img src={user} />
              </div>
            </div>
          </div>
          {/* <div className="error">Lỗi chỗ này</div> */}
        </div>
        <div className="form-groups">
          <div className="form-groups-w">
            <input type="number" placeholder="Số điện thoại" />
            <div className="icon ">
              <div className="img">
                <img src={phone} />
              </div>
            </div>
          </div>
        </div>
        <div className="form-groups">
          <div className="form-groups-w">
            <input type="mail" placeholder="Email" />
            <div className="icon ">
              <div className="img">
                <img src={mail} />
              </div>
            </div>
          </div>
        </div>
        <div className="form-groups">
          <div className="form-groups-w">
            <input type="password" placeholder="Mật khẩu" />
            <div className="icon ">
              <div className="img">
                <img src={lock} />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
