import React, { useState } from "react";
import "./ContactForm.scss";
import { useTranslation } from "react-i18next";
import {request} from '../../helpers/api';
import FormInput from "../FormInput/FormInput";
import Info from "../../modals/Info";

export default function ContactForm() {
  const { t } = useTranslation();
  const [stateForm, setStateForm] = useState({
    fullName: "",
    phoneNumber: "",
    email: "",
    content: ""
  });

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setStateForm({...stateForm, [name]: value});
  }
  const onHandleSubmit = (event) => {
    const data = {
      "name": stateForm.fullName,
      "email": stateForm.email,
      "phone": stateForm.phoneNumber,
      "content": stateForm.content,
      "isDeleted": false
    }

    request('POST', 'contact-requests', null, null, data ).then((response) => {
      if(response != null && response.data != null) {
        setIsOpen(true);
      }
      //const data = response.data[0].attributes || null;
      //setCourseDetail(data);
    });
    //onSubmit(stateForm);
    event.preventDefault();
  }
  const inputs = [
    {
      id: 1,
      name: "fullName",
      type: "text",
      placeholder: t('course_form.name.placeholder'),
      errorMessage: t('course_form.name.errorMessage'),
      iconImg: null,
      required: true,
    },
    {
      id: 2,
      name: "phoneNumber",
      type: "text",
      placeholder: t('course_form.phone.placeholder'),
      errorMessage: t('course_form.phone.errorMessage'),
      pattern: '^((\\+91-?)|0)?[0-9]{9}$',
      iconImg: null,
      required: true,
    },
    {
      id: 3,
      name: "email",
      type: "email",
      placeholder: t('course_form.email.placeholder'),
      errorMessage: t('course_form.email.errorMessage'),
      pattern: `/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/`,
      iconImg: null,
    }
  ];

  const [modalIsOpen, setIsOpen] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div className="contact-form">
      <Info open={modalIsOpen} close={closeModal} message={t('info_recieved')} />
      <form onSubmit={onHandleSubmit}>
        <div className="form-groups">
          <div className="form-groups-w">
          <label>{t('contact_form.name.label')} (<span>*</span>)</label>
            <FormInput
              key={0}
              {...inputs[0]}
              value={stateForm[inputs[0].name]}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="form-groups">
          <div className="form-groups-w">
          <label>{t('contact_form.email.label')} (<span>*</span>)</label>
            <FormInput
              key={2}
              {...inputs[2]}
              value={stateForm[inputs[2].name]}
              onChange={handleInputChange}
            />
           
          </div>
        </div>
        <div className="form-groups">
          <div className="form-groups-w">
            <label>{t('contact_form.phone.label')} (<span>*</span>)</label>
            <FormInput
              key={1}
              {...inputs[1]}
              value={stateForm[inputs[1].name]}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="form-groups">
          <div className="form-groups-w">
            <label>{t('contact_form.content.label')} </label>
            <textarea type="text" name="content" placeholder={t('contact_form.content.placeholder')} value={stateForm.content || ''} onChange={handleInputChange}/>
          </div>
        </div>
        <div className="form-groups">
          <div className="form-groups-w">
            <button type="submit" className="custom-btn">{t('submit')}</button>
          </div>
        </div>
      </form>
    </div>
  );
}
