import React from "react";
import "./Card.scss";
import icon from "../../assets/images/calendar.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {useTranslation} from 'react-i18next';

export default function Card({
  thumb = 'https://via.placeholder.com/400x300',
  time,
  title,
  excerpt,
  id,
  slug,
  cateID = 0,
  cateSlug,
  isRelatedLink = false,
  isUsingFullPathNews = false,
}) {
  const {t, i18n} = useTranslation();
  const date = new Date(time);
  const dateConverted =  date.toLocaleString(i18n.language, {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const navigate = useNavigate();
  const navigateTo = () => {
    if(isUsingFullPathNews) {
      navigate(`./${i18n.language}/posts/${cateSlug}/detail/${slug} `);
      return;
    }
    if (isRelatedLink) {
      let path = location.pathname.split("/").slice(0, -1).join("/") + `/${slug}`;
      navigate(path);
    } else {
      navigate(`./${slug}`);
    }
  };

  return (
    <div className="card">
      <div onClick={navigateTo}>
        <div className="card-wrap">
          <div className="card-thumb">
            <span
              className="aspectRatio aspectRatio--3-4"
              style={{ backgroundImage: `url(${thumb})` }}
            ></span>
          </div>
          <div className="card-meta">
            <div className="date">
              <div className="icon">
                <div className="img">
                  <img src={icon} />
                </div>
              </div>
              <div className="time">{dateConverted}</div>
            </div>
            <div className="title">{title}</div>
            <div className="excerpt">{excerpt}</div>
            <div className="action">
              <span>{t('view_more')}</span>
            </div>
          </div>
          <div className="line-bottom">
            <span></span>
          </div>
        </div>
      </div>
    </div>
  );
}
