import "./LoadingSpinner.scss";
export default function LoadingSpinner({}) {

  return (
    <div className="bara-spin-wrapper">
      <span className="bara-spin"></span>
      <span className="bara-spin"></span>
      <span className="bara-spin"></span>
      <span className="bara-spin"></span>
    </div>
  );
}
