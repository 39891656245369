import axios from "axios";
import axiosRetry from "axios-retry";

axiosRetry(axios, {
  retries: 5, // number of retries
  retryDelay: (retryCount) => {
      console.log(`retry attempt: ${retryCount}`);
      return retryCount * 1500; // time interval between retries
  },
  // retryCondition: (error) => {
  //     // if retry condition is not specified, by default idempotent requests are retried
  //     return error?.response?.status === 503;
  // },
});

function getQueryCheckRelationFieldHasData(field) {
  return `&filters[$and][0]${field}[publishedAt][$notNull]=true&filters[$and][1]${field}[id][$notNull]=true`;
}

function postToURL(url, payload) {
  let config = {
    method: "POST",
    data: payload,
    baseURL: url,
    headers: {
      "Content-type": "application/json",
    },
    // validateStatus: function (status) {
    //   return status >= 200 && status < 400;
    // },
  };

  return axios(config)
    .then(function (response) {
      if (response.data) {
        return response.data;
      } else {
        throw new Error("Invalid return");
      }
    })
    .catch(function (error) {
      console.log(`${error.message}`, error);
      return Promise.reject(error);
    });
}

function request(method, uri, language, moreRequestProps = "", body, headers) {
  let config = {
    method: method.toLowerCase(),
    url: uri,
    baseURL: process.env.REACT_APP_BASEURL,
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_TOKEN} `,
    },
    // validateStatus: function (status) {
    //   return status >= 200 && status < 400;
    // },
  };

  if (method === "POST") {
    config.data = {
      data: body,
    };
  } else if (method === "GET") {
    config.url = `${uri}?populate=deep&filters[isDeleted][$eq]=false&locale=${language}${moreRequestProps}`;
  }

  return axios(config).then(function (response) {
      if (response.data) {
        return response.data;
      } else {
        throw new Error("Invalid return");
      }
    })
    .catch(function (error) {
      console.log(`${error.message}`, error);
      return Promise.reject(error);
    });
}

function spreadArrayResponse(arrResponse) {
  return axios.spread(arrResponse);
}

function multipleRequest(arrRequest) {
  return axios.all(arrRequest);
}

export {
  postToURL,
  request,
  multipleRequest,
  spreadArrayResponse,
  getQueryCheckRelationFieldHasData,
};
