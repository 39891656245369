import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import "./LogoFooter.scss";

export default function LogoFooter({}) {
  // const window = document.querySelector("html");
  const window_width = window.innerWidth;
  return (
    <div className="logo">
      <Link to={"./"}>
        <div className="img">
          <img src={logo} />
        </div>
      </Link>
    </div>
  );
}
