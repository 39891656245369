import { LanguageSupported } from "./language";

export function isNeedChangeToUrlLocale(language) {
  const currentLanguageCode = localStorage.getItem("i18nextLng") || "";
  if (language && language !== currentLanguageCode) {
    return true;
  }

  return false;
}

export function isValidLanguageParam(locale) {
  return (
    locale === LanguageSupported.EN.name || locale === LanguageSupported.VI.name
  );
}
