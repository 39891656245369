import "./SocialSharing.scss";
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";

export default function SocialSharing(props) {
  const { link = window.location.href, quote = "Dummy text!", hashtag="#seedvn", ...inputProps } = props;
  return (
    <div className="bara-social-wrapper">
      <FacebookShareButton
        url={link}
        quote={quote}
        hashtag={hashtag}
      >
        <FacebookIcon size={32} round />
      </FacebookShareButton>
      <LinkedinShareButton
        url={link}
        quote={quote}
        hashtag={hashtag}
      >
        <LinkedinIcon size={32} round />
      </LinkedinShareButton>
      <TelegramShareButton
        url={link}
        quote={quote}
        hashtag={hashtag}
      >
        <TelegramIcon size={32} round />
      </TelegramShareButton>
      <TwitterShareButton
        url={link}
        quote={quote}
        hashtag={hashtag}
      >
        <TwitterIcon size={32} round />
      </TwitterShareButton>
    </div>
  );
}
